<template>
  <div>
    <b-card title="Historie" class="historie">
      <b-form @submit.prevent class="px-2">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Implantationsdatum"
                  label-for="implantationsdatum_date"
                  label-cols-md="4"
                >
                  <b-input-group class="mb-1">
                    <b-form-input
                      id="implantationsdatum_date"
                      v-model="patientInfo.implantationsdatum_date"
                      type="text"
                      placeholder="DD.MM.YYYY"
                      v-mask="dateMask"
                      autocomplete="off"
                      show-decade-nav
                      :readonly="readOnly"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="date"
                        value-as-date
                        show-decade-nav
                        button-only
                        button-variant="outline-primary"
                        right
                        size="sm"
                        locale="de"
                        aria-controls="example-input"
                        :disabled="readOnly"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Ef [%]"
                  label-for="ef_prozent"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.ef_prozent"
                    id="ef_prozent"
                    type="number"
                    placeholder="Ef [%]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="PCI vs Stent"
                  label-for="pci_vs_stent"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.pci_vs_stent"
                    :options="pci_vs_stents"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Myokardinfarkt"
                  label-for="historie_myokardinfarkt"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.historie_myokardinfarkt"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Op dringlich"
                  label-for="historie_op_dringlich"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.historie_op_dringlich"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Re op"
                  label-for="historie_re_op"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.historie_re_op"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card title="TTE" class="op">
      <b-form @submit.prevent class="px-2">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-card class="bordered-card" title="Klappenstatus">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Mitralinsuffizienz"
                        label-for="historie_tte_klappenstatus_mitralinsuff"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.historie_tte_klappenstatus_mitralinsuff
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Mitralinsuffizienz"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Mitralstenose"
                        label-for="historie_tte_klappenstatus_mitralstenose"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.historie_tte_klappenstatus_mitralstenose
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Mitralstenose"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Aorteninsuffizienz"
                        label-for="historie_tte_klappenstatus_aorteninsuff"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.historie_tte_klappenstatus_aorteninsuff
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Aorteninsuffizienz"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Aortenstenose"
                        label-for="historie_tte_klappenstatus_aortenstenose"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.historie_tte_klappenstatus_aortenstenose
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Aortenstenose"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Trikuspidalklappeninsuffizienz"
                        label-for="historie_tte_klappenstatus_trikuspidalklappeninsuff"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.historie_tte_klappenstatus_trikuspidalklappeninsuff
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Trikuspidalklappeninsuffizienz"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-card class="bordered-card" title="Rechter Ventrikel">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Gemessener Gradient über Triskuspidalklappe [mmHg]"
                        label-for="historie_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.historie_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe
                          "
                          :options="nichterhoben"
                          taggable
                          :disabled="readOnly"
                          placeholder=""
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="TAPSE [mm]"
                        label-for="historie_tte_rechter_ventrikel_tapse"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.historie_tte_rechter_ventrikel_tapse
                          "
                          :options="nichterhoben"
                          taggable
                          :disabled="readOnly"
                          placeholder="TAPSE [mm]"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12"></b-col>
              <b-col cols="12"></b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card title="Labor" class="historie">
      <b-form @submit.prevent class="px-2">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Krea [mg/dl]"
                  label-for="historie_lab_krea"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.historie_lab_krea"
                    id="historie_lab_krea"
                    type="number"
                    placeholder="Krea [mg/dl]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="GFR [ml/minute/1,73m^2]"
                  label-for="historie_lab_gfr"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.historie_lab_gfr"
                    id="historie_lab_gfr"
                    type="number"
                    placeholder="GFR [ml/minute/1,73m^2]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="CRP [mg/dl]"
                  label-for="historie_lab_crp"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.historie_lab_crp"
                    id="historie_lab_crp"
                    type="number"
                    placeholder="CRP [mg/dl]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="ProBNP [pg/ml]"
                  label-for="historie_lab_probnp"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.historie_lab_probnp"
                    id="historie_lab_probnp"
                    type="number"
                    placeholder="ProBNP [pg/ml]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="CK-CKmb [E/l]"
                  label-for="historie_lab_ck_ckmb"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.historie_lab_ck_ckmb"
                    id="historie_lab_ck_ckmb"
                    type="number"
                    placeholder="CK-CKmb [E/l]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Hb [g/dl]"
                  label-for="historie_lab_hb"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.historie_lab_hb"
                    id="historie_lab_hb"
                    type="number"
                    placeholder="Hb [g/dl]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Thrombinzeit [s]"
                  label-for="historie_lab_thrombinzeit"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.historie_lab_thrombinzeit"
                    id="historie_lab_thrombinzeit"
                    type="number"
                    placeholder="Thrombinzeit [s]"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card title="Vorerkrankungen" class="historie">
      <b-form @submit.prevent class="px-2">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="COPD"
                  label-for="historie_vorerkrankungen_copd"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.historie_vorerkrankungen_copd"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Diabetes mellitus"
                  label-for="historie_vorerkrankungen_diabetes_mellitus"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.historie_vorerkrankungen_diabetes_mellitus
                    "
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <template
                v-if="
                  patientInfo.historie_vorerkrankungen_diabetes_mellitus ===
                  'ja'
                "
              >
                <b-col cols="12" key="histvorerkrdmbmedikation">
                  <b-form-group
                    label="DM Medikation"
                    label-for="historie_vorerkrankungen_diabetes_mellitus_medikation"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.historie_vorerkrankungen_diabetes_mellitus_medikation
                      "
                      :options="['Oad', 'Insulin']"
                      multiple
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </template>
              <b-col cols="12">
                <b-form-group
                  label="Tumor"
                  label-for="historie_vorerkrankungen_tumor"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.historie_vorerkrankungen_tumor"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Dialyse"
                  label-for="historie_vorerkrankungen_dialyse"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.historie_vorerkrankungen_dialyse"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Amputation"
                  label-for="historie_vorerkrankungen_amputation"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.historie_vorerkrankungen_amputation"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Voroperation"
                  label-for="historie_vorerkrankungen_voroperation"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.historie_vorerkrankungen_voroperation"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Akute Infektionen"
                  label-for="historie_vorerkrankungen_akute_infektionen"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.historie_vorerkrankungen_akute_infektionen
                    "
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Neurologische Vorerkrankungen"
                  label-for="historie_vorerkrankungen_neurologische_vorerkrankungen"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="
                      patientInfo.historie_vorerkrankungen_neurologische_vorerkrankungen
                    "
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Alkoholabsus"
                  label-for="historie_vorerkrankungen_alkoholabsus"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.historie_vorerkrankungen_alkoholabsus"
                    :options="yesno"
                    :disabled="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="STS Score"
                  label-for="historie_vorerkrankungen_sts_score"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.historie_vorerkrankungen_sts_score"
                    id="historie_vorerkrankungen_sts_score"
                    type="number"
                    placeholder="STS Score"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { convertDate2Str, dateMask } from "@core/utils/utils";
import { mapState, mapActions } from "vuex";
import config from "@/config";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  timers: {
    updatePatient: config.TIMER_OPTION,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        implantationsdatum_date: "",
        ef_prozent: 0,
        pci_vs_stent: "",
        historie_myokardinfarkt: "",
        historie_op_dringlich: "",
        historie_re_op: "",
        historie_tte_klappenstatus_mitralinsuff: "",
        historie_tte_klappenstatus_mitralstenose: "",
        historie_tte_klappenstatus_aorteninsuff: "",
        historie_tte_klappenstatus_aortenstenose: "",
        historie_tte_klappenstatus_trikuspidalklappeninsuff: "",
        historie_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe:
          "",
        historie_tte_rechter_ventrikel_tapse: "",
        historie_lab_krea: "",
        historie_lab_gfr: "",
        historie_lab_crp: "",
        historie_lab_probnp: "",
        historie_lab_ck_ckmb: "",
        historie_lab_hb: "",
        historie_lab_thrombinzeit: "",
        historie_vorerkrankungen_copd: "",
        historie_vorerkrankungen_diabetes_mellitus: "",
        historie_vorerkrankungen_diabetes_mellitus_medikation: [],
        historie_vorerkrankungen_tumor: "",
        historie_vorerkrankungen_dialyse: "",
        historie_vorerkrankungen_amputation: "",
        historie_vorerkrankungen_voroperation: "",
        historie_vorerkrankungen_akute_infektionen: "",
        historie_vorerkrankungen_neurologische_vorerkrankungen: "",
        historie_vorerkrankungen_alkoholabsus: "",
        historie_vorerkrankungen_sts_score: "",
      },
      hospital: null,
      hospitals: [],
      yesno: ["ja", "nein"],
      nichterhoben: ["nicht erhoben"],
      jnnichterhoben: ["ja", "nein", "nicht erhoben"],
      pci_vs_stents: ["PCI", "Stent"],
      birth: "",
      gender: "",
      date: null,
      date1: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    date(v, ov) {
      if (v) {
        this.patientInfo.implantationsdatum_date = convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    this.fetchHospitals();
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  // beforeDestroy() {
  //   this.updatePatient()
  // },
  methods: {
    dateMask,
    ...mapActions("patient", ["updatePatientById"]),
    updatePatient() {
      if (this.readOnly) {
        return;
      }

      let activeElement = document.activeElement;
      let blockingElements = ["input", "textarea"];

      // check whether an input field is the active elemnent,
      //if it is, do not interrupt the users entries with updating
      // the patientInfo but return instead
      if (
        activeElement &&
        blockingElements.indexOf(activeElement.tagName.toLowerCase()) !== -1
      ) {
        return;
      }

      this.updatePatientById(this.patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    fetchHospitals() {
      this.$http
        .get("/hospitals/list_for_update")
        .then((res) => {
          this.hospitals = res.data.hospitals;
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while fetching hospitals."
          );
        });
    },
    handleSave() {
      this.$http
        .patch(`/patients/update/${this.$route.params.id}`, this.patientInfo)
        .then((res) => {
          this.showToast("success", "Success", "Successfully updated.");
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while saving the patient."
          );
        });
    },
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.implantationsdatum_date = v.implantationsdatum_date;
      this.patientInfo.ef_prozent = v.ef_prozent;
      this.patientInfo.pci_vs_stent = v.pci_vs_stent;
      this.patientInfo.historie_myokardinfarkt = v.historie_myokardinfarkt;
      this.patientInfo.historie_op_dringlich = v.historie_op_dringlich;
      this.patientInfo.historie_re_op = v.historie_re_op;
      this.patientInfo.historie_tte_klappenstatus_mitralinsuff =
        v.historie_tte_klappenstatus_mitralinsuff;
      this.patientInfo.historie_tte_klappenstatus_mitralstenose =
        v.historie_tte_klappenstatus_mitralstenose;
      this.patientInfo.historie_tte_klappenstatus_aorteninsuff =
        v.historie_tte_klappenstatus_aorteninsuff;
      this.patientInfo.historie_tte_klappenstatus_aortenstenose =
        v.historie_tte_klappenstatus_aortenstenose;
      this.patientInfo.historie_tte_klappenstatus_trikuspidalklappeninsuff =
        v.historie_tte_klappenstatus_trikuspidalklappeninsuff;
      this.patientInfo.historie_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe =
        v.historie_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe;
      this.patientInfo.historie_tte_rechter_ventrikel_tapse =
        v.historie_tte_rechter_ventrikel_tapse;
      this.patientInfo.historie_lab_krea = v.historie_lab_krea;
      this.patientInfo.historie_lab_gfr = v.historie_lab_gfr;
      this.patientInfo.historie_lab_crp = v.historie_lab_crp;
      this.patientInfo.historie_lab_probnp = v.historie_lab_probnp;
      this.patientInfo.historie_lab_ck_ckmb = v.historie_lab_ck_ckmb;
      this.patientInfo.historie_lab_hb = v.historie_lab_hb;
      this.patientInfo.historie_lab_thrombinzeit = v.historie_lab_thrombinzeit;
      this.patientInfo.historie_vorerkrankungen_copd =
        v.historie_vorerkrankungen_copd;
      this.patientInfo.historie_vorerkrankungen_diabetes_mellitus =
        v.historie_vorerkrankungen_diabetes_mellitus;
      this.patientInfo.historie_vorerkrankungen_diabetes_mellitus_medikation =
        v.historie_vorerkrankungen_diabetes_mellitus_medikation;
      this.patientInfo.historie_vorerkrankungen_tumor =
        v.historie_vorerkrankungen_tumor;
      this.patientInfo.historie_vorerkrankungen_dialyse =
        v.historie_vorerkrankungen_dialyse;
      this.patientInfo.historie_vorerkrankungen_amputation =
        v.historie_vorerkrankungen_amputation;
      this.patientInfo.historie_vorerkrankungen_voroperation =
        v.historie_vorerkrankungen_voroperation;
      this.patientInfo.historie_vorerkrankungen_akute_infektionen =
        v.historie_vorerkrankungen_akute_infektionen;
      this.patientInfo.historie_vorerkrankungen_neurologische_vorerkrankungen =
        v.historie_vorerkrankungen_neurologische_vorerkrankungen;
      this.patientInfo.historie_vorerkrankungen_alkoholabsus =
        v.historie_vorerkrankungen_alkoholabsus;
      this.patientInfo.historie_vorerkrankungen_sts_score =
        v.historie_vorerkrankungen_sts_score;
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.historie {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
